// eslint-disable-next-line @typescript-eslint/no-explicit-any
import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, SlotId, mappedAudioSprites } from '../config';
import { BetBonusReward, BetReward, EventTypes, GameMode, ISettledBet, UserBonus, reelSets } from '../global.d';
import {
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsBuyFeaturePurchased,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsDuringWinCountUpAnimation,
  setIsDuringWinLineAnimation,
  setIsFadeOut,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setIsSixthReelReverseSpin,
  setIsSlotBusy,
  setIsTimeoutErrorMessage,
  setIsTurboSpin,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { ReelSetType, isStoppedGql, slotBetGql } from '../gql/query';
import {
  getGameModeByBonusId,
  getSpinResult,
  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isFreeSpinMode,
  isRegularMode,
  nextTick,
  normalizeCoins,
  showCurrency,
} from '../utils';

import Animation from './animations/animation';
import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BgmControl } from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_LONG_SPIN_REEL,
  ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME,
  ANTICIPATION_SYMBOLS_ID,
  FREE_SPINS_TIME_OUT_BANNER,
  GameViewObjectPriorities,
  REELS_AMOUNT,
  SETUP_REEL_POSITION_REEL_INDEX,
  SlotMachineState,
  eventManager,
} from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuButton from './controlButtons/menuBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import { FreeRoundBonusController } from './freeRoundBonus/freeRoundBonusController';
import { FreeRoundsPopup } from './freeRoundBonus/freeRoundsPopup';
import { FreeRoundsEndPopup } from './freeRoundBonus/freeRoundsPopupEnd';
import { isGetFreeSpinBonus } from './freeRoundBonus/helper';
import GameView from './gameView/gameView';
import LinesContainer from './lines/linesContainer';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import { SlotsStopDisplayContainer } from './reels/slotsStopDisplayContainer';
import SafeArea from './safeArea/safeArea';
import { SCENE_CHANGE_FADE_TIME } from './sceneChange/config';
import SceneChange from './sceneChange/sceneChange';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;
  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public menuBtn: MenuButton;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBtn: InfoBtn;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(application, slotConfig, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public slotsStopDisplayContainer: SlotsStopDisplayContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public sceneChange: SceneChange;

  public infoBuyFeatureIcon: PIXI.Container;

  private constructor(
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();
    // todo add if bonus logic

    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;

    setPrevReelsPosition(startPosition.slice(0, 5));
    const reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;

    setReelSetId(reelSet.id);

    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    const spinResult = getSpinResult({
      reelPositions: startPosition.slice(0, 5),
      reelSet,
      icons: slotConfig.icons,
    });

    this.slotsStopDisplayContainer = new SlotsStopDisplayContainer(spinResult);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, true);

    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.gameView = new GameView({
      slotStopDisplayContainer: this.slotsStopDisplayContainer,
      linesContainer: new LinesContainer(slotConfig.winLines),
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
      lines: slotConfig.winLines,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });

    if (isBuyFeatureEnabled(slotConfig.clientSettings.features)) {
      this.initBuyFeature();
    }

    if (setBrokenBuyFeature()) {
      setIsSlotBusy(true);
      eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
      nextTick(() => {
        eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
        if (this.state === SlotMachineState.IDLE) eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
      });
    }

    this.menuBtn = new MenuButton();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();
    this.sceneChange = new SceneChange();
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);

    this.application.stage.addChild(
      new Background(),
      safeArea,
      this.menuBtn,
      this.turboSpinBtn,
      this.spinBtn,
      this.betBtn,
      this.autoplayBtn,
      this.infoBtn,
      new FreeRoundsPopup(),
      new FreeRoundsEndPopup(),
      new BottomContainer(),
      new Phoenix(),
      this.sceneChange,
      new FadeArea(),
    );

    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();

    if (setBrokenGame()) {
      this.onBrokenGame();
    }
    new FreeRoundBonusController();
  }

  private initBuyFeature(): void {
    const buyFeatureBtn = new BuyFeatureBtn();
    const backDrop = new Backdrop();
    const buyFeaturePopup = new BuyFeaturePopup();
    const buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();

    buyFeatureBtn.zIndex = GameViewObjectPriorities['BUY_FEATURE_BUTTON'];
    backDrop.zIndex = GameViewObjectPriorities['BUY_FEATURE_BACKDROP'];
    buyFeaturePopup.zIndex = GameViewObjectPriorities['BUY_FEATURE_POPUP'];
    buyFeaturePopupConfirm.zIndex = GameViewObjectPriorities['BUY_FEATURE_POPUP_CONFIRM'];

    this.gameView.addChild(buyFeatureBtn, backDrop, buyFeaturePopup, buyFeaturePopupConfirm);
  }

  private async onBrokenGame(): Promise<void> {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonusId);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setCurrentBonusId(setCurrentBonus().id);
    setReelSetId(setCurrentBonus().reelSetId);

    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    if (setCurrentFreeSpinsTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      });
    } else {
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    }
    eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
      text: 'freeSpinsTitleText',
      spins: setCurrentBonus().rounds,
      currentSpin: setCurrentBonus().currentRound,
    });

    if (
      setUserLastBetResult().reelSetId === reelSets[GameMode.REGULAR] ||
      setUserLastBetResult().reelSetId === reelSets[GameMode.BUY_FEATURE]
    ) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: i18n.t('freeSpinsTitle'),
        description: i18n.t('freeSpinsText', {
          spin: setCurrentBonus().rounds,
        }),
        btnText: i18n.t('pressToStart'),
        callback: () => {
          this.setState(SlotMachineState.IDLE);
        },
      });
    } else {
      this.setState(SlotMachineState.IDLE);
    }
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.WIN_COUNT_UP_ANIM_END, this.onWinCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.WIN_LINE_ANIM_END, this.onWinLineEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      BgmControl.playBgmGameMode(setGameMode());
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.SONG_021_06_BigWin_Loop });
      }
    });
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';
    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN';
    })?.userBonus;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
      setReelSetId(settings.reelSetId);
      const reelSet = setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId);
      const spinResult = getSpinResult({
        reelPositions: settings.reelPositions.slice(0, 5),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });
      this.miniPayTableContainer.setSpinResult(spinResult);
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: reelSet!,
        reelPositions: settings.reelPositions,
      });
      eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, true);
      setPrevReelsPosition(settings.reelPositions.slice(0, 5));
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }

    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
      });
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, setIsContinueAutoSpinsAfterFeature());

      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isFreeSpinMode(settings.mode)) {
      const bonus = this.getFreeSpinBonus();
      if (!bonus) throw new Error('Some went wrong');

      setCurrentBonusId(setCurrentBonus().id);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: i18n.t('freeSpinsTitle'),
          description: i18n.t('freeSpinsText', {
            spin: this.nextResult?.bet.data.bonuses[0]!.rounds,
          }),
          btnText: i18n.t('pressToStart'),
          callback: () => {
            if (AudioApi.isRestricted) {
              BgmControl.handleChangeRestriction();
            }
            this.setState(SlotMachineState.IDLE);
          },
        });
      } else {
        this.setState(SlotMachineState.IDLE);
      }
    }
  }

  private startFreeSpins(): void {
    const getBonus = this.getBonusFromResult()!;

    setCurrentBonus({
      ...getBonus,
      isActive: true,
      currentRound: 0,
    });
    setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
    setIsFreeSpinsWin(true);

    const animationChain = new AnimationChain();
    {
      const sceneChange = Tween.createDelayAnimation(1000);
      sceneChange.addOnComplete(() => {
        eventManager.emit(EventTypes.SCENE_CHANGE_DOWN, () => {
          if (setCurrentFreeSpinsTotalWin() === 0) {
            eventManager.emit(EventTypes.HIDE_WIN_LABEL);
          }
          eventManager.emit(EventTypes.CHANGE_MODE, {
            mode: GameMode.FREE_SPINS,
            reelPositions: [0, 0, 0, 0, 0],
            reelSetId: reelSets[GameMode.FREE_SPINS]!,
          });
          eventManager.emit(EventTypes.UPDATE_FREE_SPINS_COUNT, setCurrentBonus().rounds, 0, true);
        });
      });
      animationChain.appendAnimation(sceneChange);
    }
    animationChain.start();
  }

  private async endFreeSpins(): Promise<void> {
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: setCurrentBonus().betId } },
      fetchPolicy: 'network-only',
    });

    const { reelSetId, reelPositions, sixReelResult } = {
      reelSetId: bet.data.bet.reelSetId,
      reelPositions: bet.data.bet.result.reelPositions,
      sixReelResult: bet.data.bet.data.features.extraReelReward,
    };

    AudioApi.play({ type: ISongs.SONG_021_04_TotalWinBanner, stopPrev: true });
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());

    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();

    const callback = () => {
      eventManager.emit(EventTypes.SCENE_CHANGE_UP, () => {
        eventManager.emit(EventTypes.CHANGE_MODE, {
          mode: GameMode.REGULAR,
          reelSetId,
          reelPositions,
          sixReelResult,
        });
      });

      setTimeout(() => {
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      }, 100);

      setTimeout(() => {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency: showCurrency(setCurrency()),
          }),
        );
      }, SCENE_CHANGE_FADE_TIME);
    };

    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })} `,
        preventDefaultDestroy: true,
        callback,
        title: i18n.t('youWon'),
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })}`,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: i18n.t('youWon'),
      });
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(index = 0): void {
    this.reelsContainer.reels[index]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, current.toString(), total.toString(), false);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          this.removeErrorHandler(SETUP_REEL_POSITION_REEL_INDEX);
          this.dynamicReelSetChange(this.nextResult!.bet.reelSet.id);
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.nextResult.bet.result.reelPositions,
            this.getScatterCount(this.nextResult.bet.result.spinResult),
            this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
          );
          eventManager.emit(EventTypes.SETUP_SIXTH_REEL_POSITIONS, this.nextResult.bet.data.features.extraReelReward);
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(!!isTurboSpin);

      if (isFreeSpinMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        eventManager.emit(EventTypes.UPDATE_FREE_SPINS_COUNT, setCurrentBonus().rounds, bonus.currentRound, false);
        setCurrentBonus(bonus);
      }

      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      const spinAnimation: SpinAnimation = reel!.createSpinAnimation(isTurboSpin);

      if (i === SETUP_REEL_POSITION_REEL_INDEX) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler(SETUP_REEL_POSITION_REEL_INDEX);
            this.dynamicReelSetChange(this.nextResult!.bet.reelSet.id);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getScatterCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
            );
            eventManager.emit(EventTypes.SETUP_SIXTH_REEL_POSITIONS, this.nextResult.bet.data.features.extraReelReward);

            const betAmount = normalizeCoins(setBetAmount());
            const winAmount = normalizeCoins(this.nextResult.bet.result.winCoinAmount);
            const multiplier = winAmount / betAmount;
            if (multiplier >= 1000) {
              eventManager.emit(EventTypes.PHOENIX_START);
            }
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin));
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getBonusFromResult(): UserBonus | undefined {
    return (
      this.nextResult?.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetBonusReward',
      ) as BetBonusReward
    )?.userBonus;
  }

  private isFreeSpins(): boolean {
    return isGetFreeSpinBonus(this.nextResult!);
  }

  private onWinCountUpEnd() {
    if (setIsDuringWinLineAnimation()) {
      return;
    }
    this.endAllWinAnimation();
  }

  private onWinLineEnd() {
    if (setIsDuringWinCountUpAnimation()) {
      return;
    }
    this.endAllWinAnimation();
  }

  private endAllWinAnimation(): void {
    const mode = setGameMode();
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
    eventManager.emit(EventTypes.ADD_WIN_AMOUNT, this.nextResult!.bet.result.winCoinAmount);

    if (!isFreeSpinMode(mode)) {
      setWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      if (this.isFreeSpins()) {
        this.startFreeSpins();
      }
    } else {
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

      if (this.isAddFreeSpins()) {
        this.addFreeSpin();
        return;
      }
    }
    this.setState(SlotMachineState.IDLE);
  }

  private dynamicReelSetChange(reelId: string): void {
    if (setReelSetId() !== reelId) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === reelId)!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelId);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    if (setBrokenBuyFeature()) {
      setBrokenBuyFeature(false);
    }
    this.onSpinStop(isTurboSpin);
    setIsBuyFeaturePurchased(false);
  }

  private getAnticipationStartReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    if (setGameMode() === GameMode.FREE_SPINS) return REELS_AMOUNT;

    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        if (spinResult[j]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT * 2]!.id === symbolId) currentCount += 1;

        if (currentCount >= count! && j + 1 < REELS_AMOUNT)
          minReelId = Math.min(minReelId, ANTICIPATION_LONG_SPIN_REEL);
      }
    });
    return minReelId;
  }

  private getScatterCount(spinResult: Icon[]): number[] {
    let count = 0;
    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (col.some((icon) => icon.id === SlotId.SC1 || icon.id === SlotId.SC2)) {
          count += 1;
          return count;
        }
        return 0;
      })
      .value();
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (!setIsDuringWinCountUpAnimation()) {
      if (this.state === SlotMachineState.IDLE || this.state === SlotMachineState.WINNING) {
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      }
    }
  }

  private getUserBonusId(result: ISettledBet): string {
    return (result.rewards.find((reward) => reward.__typename === 'BetBonusReward') as BetBonusReward).userBonusId;
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions.slice(0, 5),
      reelSet: setSlotConfig().reels.find((reelSet) => reelSet.id === result.bet.reelSet.id)!,
      icons: setSlotConfig().icons,
    });

    result.bet.result.spinResult = spinResult;
    setPrevReelsPosition(result.bet.result.reelPositions.slice(0, 5));
    this.nextResult = result;
    setNextResult(result);

    const gameMode = setGameMode();
    if (isRegularMode(gameMode) || isBuyFeatureMode(gameMode)) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult.balance.placed);
      setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    }

    console.info(this.nextResult);
    if (this.nextResult.bet.data.features.gameRoundStore) {
      console.info(this.nextResult.bet.data.features.gameRoundStore.nextPayouts);
    }
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(this.nextResult!.bet.result.spinResult);
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length - this.reelsContainer.reels[x]!.position + y - 1) %
        this.reelsContainer.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    eventManager.emit(EventTypes.RESIZE_UI_BUTTON, width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private isAddFreeSpins(): boolean {
    if (!isFreeSpinMode(setGameMode())) return false;
    if (!this.isFreeSpins()) return false;

    return true;
  }

  private addFreeSpin() {
    const addBonus = this.nextResult?.bet.data.bonuses[0]!;
    const prevRounds = setCurrentBonus().rounds;
    const newTotalRounds = addBonus.rounds + addBonus.roundsPlayed;
    const addRounds = newTotalRounds - prevRounds;

    console.info({ addBonus, prevRounds, newTotalRounds });

    if (addBonus) {
      setCurrentBonus({
        ...setCurrentBonus(),
        rounds: addBonus.rounds + addBonus.roundsPlayed,
      });

      eventManager.emit(EventTypes.ADD_FREE_SPINS, setCurrentBonus(), addRounds);
      eventManager.emit(
        EventTypes.UPDATE_FREE_SPINS_COUNT,
        setCurrentBonus().rounds,
        setCurrentBonus().currentRound,
        true,
      );

      // if there is no win
      if (
        this.getScatterCount(this.nextResult!.bet.result.spinResult).length > 0 &&
        this.nextResult!.bet.result.winCoinAmount === 0
      ) {
        eventManager.emit(EventTypes.START_ADD_FREE_SPINS_SYMBOL_ANIMATION, this.nextResult!);
      }

      const idleDelay = Tween.createDelayAnimation(2000);
      idleDelay.addOnComplete(() => {
        this.setState(SlotMachineState.IDLE);
      });
      idleDelay.start();
    }
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature() || setIsFadeOut(),
    );

    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      if (isFreeSpinMode(setGameMode())) {
        if (setCurrentBonus().currentRound > 0) {
          eventManager.emit(EventTypes.MOVE_FREE_SPINS_STOCK);
        }
        if (setCurrentBonus().isActive && setCurrentBonus().rounds === setCurrentBonus().currentRound) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          const endDelay = Tween.createDelayAnimation(1000);
          endDelay.addOnComplete(() => this.endFreeSpins());
          endDelay.start();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      } else if (setIsContinueAutoSpinsAfterFeature()) {
        this.skipAnimations();
        setTimeout(() => eventManager.emit(EventTypes.SPACE_KEY_SPIN), 500);
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      const jingleDelay = new AnimationChain();

      if (setIsSixthReelReverseSpin() && !this.reelsContainer.forcedStop) {
        const sixReelReverseSpinDelay = Tween.createDelayAnimation(1300);
        jingleDelay.appendAnimation(sixReelReverseSpinDelay);
      } else if (this.hasWin()) {
        const winAnimStartDelay = Tween.createDelayAnimation(200);
        jingleDelay.appendAnimation(winAnimStartDelay);
      }

      if (this.isFreeSpins() && !isFreeSpinMode(setGameMode())) {
        const featureTriggerDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SONG_FeatureTrigger].duration);
        featureTriggerDelay.addOnStart(() => {
          AudioApi.play({ type: ISongs.SONG_FeatureTrigger, stopPrev: true });
        });
        jingleDelay.appendAnimation(featureTriggerDelay);
      }

      if (jingleDelay.animations.length > 0) {
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, setIsTurboSpin());
      } else {
        this.endAllWinAnimation();
      }
    }
  }
}

export default SlotMachine;
