import { TextStyle } from 'pixi.js';

import { Variables } from '../../config';

const GRADIENT_GOLD_AMOUNT_COLOR_CONFIG = {
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: 0,
  stroke: '#333333',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 1,
  miterLimit: 4,
};

const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#333300',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 1,
  dropShadowBlur: 0,
  dropShadowDistance: 1,
};

export const titleTextStyle = new TextStyle({
  fontSize: 120,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 6,
  lineJoin: 'round',
});

export const descriptionsTextStyle = new TextStyle({
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#FFFFFF',
  fillGradientType: 0,
  stroke: '#EEEEEE',
  strokeThickness: 2,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 1,
  dropShadowBlur: 0,
  dropShadowDistance: 1,
  lineJoin: 'round',
});

export const btnTextStyle = new TextStyle({
  fontSize: 60,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#aa3333',
  fillGradientType: 0,
  stroke: '#aa3333',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  lineJoin: 'round',
});

export const totalWinTitleStyles = new TextStyle({
  fontSize: 140,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_AMOUNT_COLOR_CONFIG,
  strokeThickness: 7,
  lineJoin: 'round',
});

export const totalWinAmountTextStyles = new TextStyle({
  fontSize: 160,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_AMOUNT_COLOR_CONFIG,
  strokeThickness: 8,
  lineJoin: 'round',
});
