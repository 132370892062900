import 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes, GameMode, MessageFreeSpinsBannerProps, MessageWinBannerProps } from '../../global.d';
import { setIsOpenedMessageBanner } from '../../gql';
import { isRegularMode, updateCoinValueAfterBonuses } from '../../utils';
import Tween from '../animations/tween';
import BgmControl from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import {
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  GameViewObjectPriorities,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import { AddFreeSpinsCounter } from '../freeSpins/addFreeSpinsCounter';
import { FreeSpinsCounter } from '../freeSpins/freeSpinsCounter';
import FujinAttackEffect from '../fujinRaijin/fujinAttackEffect';
import FujinRaijin from '../fujinRaijin/fujinRaijin';
import RaijinAttackEffect from '../fujinRaijin/raijinAttackEffect';
import { BaseMessageBanner } from '../messageBanner/baseMessageBanner';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import ReelsFrame from '../reels/frame/reelsFrame';
import SixthReel from '../reels/sixthReel/sixthReel';
import SixthReelGear from '../reels/sixthReel/sixthReelGear';
import SixthReelPinWheel from '../reels/sixthReel/sixthReelPinWheel';
import StockContainer from '../stock/stockContainer';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';
import SlotsAnimationContainer from '../winAnimations/slotsAnimationContainer';
import { WinSymbolHightLightsContainer } from '../winAnimations/winSymbolHightLightsContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public linesContainer: PIXI.Container;

  public leftLinesContainer: PIXI.Container | null = null;

  public rightLinesContainer: PIXI.Container | null = null;

  public coinsAnimationContainer: CoinsAnimationContainer;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public baseObjectsContainer: PIXI.Container;

  public gameTitle: GameTitle;

  public gameReplay: GameReplay;

  public miniPayTableContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public addFreeSpinsCounter: AddFreeSpinsCounter;

  private messageBanner: BaseMessageBanner | null = null;

  private windowSize = { width: 0, height: 0 };

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.sortableChildren = true;

    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;

    {
      this.winLabelContainer = props.winLabelContainer;
      this.slotStopDisplayContainer = props.slotStopDisplayContainer;
      this.miniPayTableContainer = props.miniPayTableContainer;
      this.linesContainer = props.linesContainer;
      this.reelsBackgroundContainer = props.reelsBackgroundContainer;
      this.reelsContainer = props.reelsContainer;
      this.winCountUpMessage = props.winCountUpMessage;
    }

    {
      this.slotsContainer.addChild(this.reelsContainer);
      this.slotsContainer.mask = new PIXI.Graphics()
        .beginFill(0xffffff)
        .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
        .endFill();
      this.slotsContainer.addChild(this.slotsContainer.mask);
    }

    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.addFreeSpinsCounter = new AddFreeSpinsCounter();

    {
      this.baseObjectsContainer = new PIXI.Container();
      this.baseObjectsContainer.sortableChildren = true;

      this.baseObjectsContainer.addChild(new FujinRaijin());
      this.baseObjectsContainer.addChild(new SixthReelGear());
      this.baseObjectsContainer.addChild(this.reelsBackgroundContainer);
      this.baseObjectsContainer.addChild(this.slotsContainer);
      this.baseObjectsContainer.addChild(this.slotStopDisplayContainer);
      this.baseObjectsContainer.addChild(new ReelsFrame());
      this.baseObjectsContainer.addChild(new SlotsAnimationContainer());
      this.baseObjectsContainer.addChild(new WinSymbolHightLightsContainer());
      this.baseObjectsContainer.addChild(new SixthReelPinWheel());
      this.baseObjectsContainer.addChild(new FujinAttackEffect());
      this.baseObjectsContainer.addChild(new RaijinAttackEffect());
      this.baseObjectsContainer.addChild(new SixthReel());
      this.baseObjectsContainer.addChild(new StockContainer());
      this.baseObjectsContainer.addChild(new FreeSpinsCounter());
    }

    this.baseObjectsContainer.zIndex = GameViewObjectPriorities['BASE_OBJECTS_CONTAINER'];
    this.gameTitle.zIndex = GameViewObjectPriorities['GAME_TITLE'];
    this.gameReplay.zIndex = GameViewObjectPriorities['GAME_REPLAY'];
    this.miniPayTableContainer.zIndex = GameViewObjectPriorities['MINI_PAY_TABLE'];
    this.coinsAnimationContainer.zIndex = GameViewObjectPriorities['COINS_ANIMATION'];
    this.winLabelContainer.zIndex = GameViewObjectPriorities['WIN_LABEL'];
    this.winCountUpMessage.zIndex = GameViewObjectPriorities['WIN_COUNT_UP'];
    this.addFreeSpinsCounter.zIndex = GameViewObjectPriorities['ADD_FREE_SPINS_COUNTER'];

    this.addChild(this.baseObjectsContainer);
    this.addChild(this.gameTitle);
    this.addChild(this.gameReplay);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);
    this.addChild(this.addFreeSpinsCounter);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));
  }

  private createFreeSpinsMessage(props: MessageFreeSpinsBannerProps): void {
    const messageFreeSpinsBanner = new MessageFreeSpinsBanner(props).init();
    messageFreeSpinsBanner.zIndex = GameViewObjectPriorities['MESSAGE_BANNER'];

    this.messageBanner = messageFreeSpinsBanner;
    this.scaleBanner();

    this.addChild(messageFreeSpinsBanner);
  }

  private createWinMessage(props: MessageWinBannerProps): void {
    const totalWinDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SONG_021_04_TotalWinBanner].duration);
    totalWinDelay.addOnStart(() => {
      BgmControl.fadeOutAll(0);
    });
    totalWinDelay.addOnSkip(() => {
      AudioApi.fadeOut(1000, ISongs.SONG_021_04_TotalWinBanner);
    });
    AudioApi.play({ type: ISongs.SONG_021_04_TotalWinBanner, stopPrev: true });

    totalWinDelay.start();

    const messageWinBanner = new MessageWinBanner({
      ...props,
      callback: () => {
        totalWinDelay.skip();
        if (props.callback) props.callback();
      },
    }).init();
    messageWinBanner.zIndex = GameViewObjectPriorities['MESSAGE_BANNER'];

    this.messageBanner = messageWinBanner;
    this.scaleBanner();

    this.addChild(messageWinBanner);
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isRegularMode(settings.mode)) {
      updateCoinValueAfterBonuses();
    }
  }

  private resize(width: number, height: number) {
    this.windowSize = { width, height };
    this.scaleBanner();
  }

  private scaleBanner() {
    const isLandScape = this.windowSize.width > this.windowSize.height;
    if (this.messageBanner !== null && setIsOpenedMessageBanner()) {
      this.messageBanner.scale.set(isLandScape ? 1 : 0.7);
    }
  }

  private resizeGameContainer(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
