import * as PIXI from 'pixi.js';

import { Variables } from '../../config';
import { EventTypes } from '../../global.d';
import {
  setCurrentBonus,
  setIsTimeoutErrorMessage,
  setReplayBet,
  setReplayFreeSpinBets,
  setStressful,
} from '../../gql';
import i18n from '../../i18next';
import { nextTick } from '../../utils';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { SlotMachineState, eventManager } from '../config';

export const replayTextStyle: Partial<PIXI.ITextStyle> = {
  fill: 0xffffff,
  fontSize: 64,
  fontFamily: Variables.FONT_FAMILY,
};

class GameReplay extends ViewContainer {
  private text: PIXI.Text;
  private triangle: PIXI.Graphics;
  private back: PIXI.Graphics;
  private container: PIXI.Container;
  private animation: Animation | undefined;

  constructor() {
    super();
    this.init();
    this.triangle = this.initTriangle();
    this.text = this.initText();
    this.back = this.initBack();
    this.container = this.initContainer();
    this.addChild(this.back, this.container);
    this.addChild(this.container);

    if (setReplayBet()) {
      nextTick(() => {
        this.startReplay();
      });
    }
  }

  private init() {
    this.visible = false;
    this.y = -38;
  }

  private initText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t('replay').toLocaleUpperCase(), replayTextStyle);
    text.anchor.set(0, 0.5);
    text.position.set(this.triangle.width, 0);
    return text;
  }

  private initTriangle(): PIXI.Graphics {
    const triangle = new PIXI.Graphics();
    const size = 16 * 2;
    triangle
      .beginFill(0xff0000)
      .drawPolygon([
        size,
        0,
        Math.floor(Math.cos(Math.PI * 0.6666) * size),
        Math.floor(Math.sin(Math.PI * 0.6666) * size),
        Math.floor(Math.cos(Math.PI * 1.3333) * size) + 1,
        Math.floor(Math.sin(Math.PI * 1.3333) * size) + 1,
      ]);
    return triangle;
  }

  private initBack(): PIXI.Graphics {
    const back = new PIXI.Graphics();
    back
      .beginFill(0x000000, 0.3)
      .drawRect(
        -this.triangle.width / 2,
        -this.text.height / 2,
        this.triangle.width + this.triangle.width / 2 + this.text.width,
        this.text.height,
      );
    return back;
  }

  private initContainer(): PIXI.Container {
    const container = new PIXI.Container();
    container.visible = true;
    container.addChild(this.triangle, this.text);
    this.animation = Tween.createDelayAnimation(500);
    this.animation.addOnComplete(() => {
      container.visible = !container.visible;
    });
    this.animation.isLoop = true;
    this.animation.start();
    return container;
  }

  private setVisible(visible: boolean) {
    this.visible = visible;
    if (visible) {
      this.animation!.isLoop = true;

      this.animation!.start();
    } else {
      this.animation!.isLoop = false;
    }
  }

  private startReplay() {
    this.setVisible(true);
    eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    eventManager.emit(EventTypes.TOGGLE_SPIN);
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state === SlotMachineState.IDLE) {
      const replayFreeSpins = setReplayFreeSpinBets();
      const freeSpinRounds = setCurrentBonus().currentRound;
      if (replayFreeSpins.length === 1 || freeSpinRounds === replayFreeSpins.length - 1) {
        setIsTimeoutErrorMessage(true);
        setStressful({
          show: true,
          type: 'none',
          message: i18n.t('replayBetMessage'),
        });
        //setReplayBet('');
      }
    }
  }
}

export default GameReplay;
