import i18n from 'i18next';
import * as _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureTypes, GameMode, IBonus, bonusIds } from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setCoinAmount,
  setCurrency,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
} from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import { normalizeCoins, showCurrency, updateTextScale } from '../../utils';
import { SpriteButton } from '../components/button';
import {
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_X,
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y,
  FEATURE_POPUP_BET_VALUE_POSITION_X,
  FEATURE_POPUP_BET_VALUE_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_INPUT_POSITION_X,
  FEATURE_POPUP_INPUT_POSITION_Y,
  FEATURE_POPUP_INPUT_WIDTH,
  FEATURE_POPUP_MINUS_BTN_POSITION_X,
  FEATURE_POPUP_MINUS_BTN_POSITION_Y,
  FEATURE_POPUP_OK_BTN_POSITION_X,
  FEATURE_POPUP_OK_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_POSITION_X,
  FEATURE_POPUP_PLUS_BTN_POSITION_Y,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
  FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X,
  FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y,
  FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X,
  FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  eventManager,
} from '../config';
import AutoResizeText from '../text/autoResizeText';

import {
  amountTextStyle,
  betValueStyle,
  buyFeatureTitleStyle,
  totalCostTextAmountStyle,
  totalCostTextStyle,
} from './textStyles';

class BuyFeaturePopup extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: AutoResizeText;

  private totalCostText: AutoResizeText;

  private totalCostTextAmount: AutoResizeText;

  private amountText: AutoResizeText;

  private minusButton: SpriteButton;

  private plusButton: SpriteButton;

  private input: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private linesAmount: number;

  private currency = 'FUN';

  private betValue: AutoResizeText;

  isNoFunds: boolean;

  private balance: number;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = setSlotConfig().lineSet.coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostTextAmount = this.initTotalCostTextAmount();
    this.amountText = this.initAmountText();
    this.minusButton = this.initMinusBtn();
    this.plusButton = this.initPlusBtn();
    this.input = this.initInput();
    this.betValue = this.initBetValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();

    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      setIsBuyFeaturePopupOpened(true);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance?: { currency: string; amount: number }) => {
      this.balance = balance!.amount / 100;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, () => {
      this.visible = true;
      this.handleDisable();
      this.cancelBtn.disable = false;
    });

    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.visible = false;
    });

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostTextAmount);
    this.addChild(this.amountText);
    this.addChild(this.minusButton);
    this.addChild(this.plusButton);
    this.addChild(this.input);
    this.addChild(this.betValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));
    popupBg.anchor.set(0.5, 0.5);

    return popupBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t<string>('buyFeatureTitle'), buyFeatureTitleStyle);
    title.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.x = FEATURE_POPUP_TITLE_POSITION_X;
    title.anchor.set(0.5, 0);
    updateTextScale(title, this.popupBg.width - 200, 300);
    return title;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t<string>('buyFeatureTotalCost'), totalCostTextStyle);
    totalCostText.y = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y;
    totalCostText.x = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X;
    totalCostText.anchor.set(0.5, 0.5);
    updateTextScale(totalCostText, 450, 300);

    return totalCostText;
  }

  private initTotalCostTextAmount(): AutoResizeText {
    const totalCostTextAmount = new AutoResizeText(this.getTotalCost(), {
      ...totalCostTextAmountStyle,
      maxWidth: FEATURE_POPUP_INPUT_WIDTH + 30 * 2,
    });
    totalCostTextAmount.y = FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y;
    totalCostTextAmount.x = FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X;
    totalCostTextAmount.anchor.set(0.5, 0.5);

    return totalCostTextAmount;
  }

  private initAmountText(): AutoResizeText {
    const amountText = new AutoResizeText(i18n.t<string>('buyFeatureBetPerGame'), amountTextStyle);
    amountText.y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y + 28;
    amountText.x = FEATURE_POPUP_AMOUNT_TEXT_POSITION_X;
    amountText.anchor.set(0.5, 0.5);
    updateTextScale(amountText, 380, 250);
    return amountText;
  }

  private initMinusBtn(): SpriteButton {
    const button = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureMinusBtn,
        hover: ResourceTypes.buyFeatureMinusBtnHover,
        press: ResourceTypes.buyFeatureMinusBtnPressed,
        disable: ResourceTypes.buyFeatureMinusBtnDisable,
      },
      () => {
        if (this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
          // eslint-disable-next-line no-plusplus
          this.betAmount--;
          setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
          this.updateBets();
          this.handleDisable();
          setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
          AudioApi.play({ type: ISongs.SONG_SFX_UI_BetChange });
        }
      },
    );
    button.position.set(FEATURE_POPUP_MINUS_BTN_POSITION_X, FEATURE_POPUP_MINUS_BTN_POSITION_Y);
    button.scale.set(0.5, 0.5);
    button.anchor.set(0.5, 0.5);
    return button;
  }

  private initPlusBtn(): SpriteButton {
    const button = new SpriteButton(
      {
        default: ResourceTypes.buyFeaturePlusBtn,
        hover: ResourceTypes.buyFeaturePlusBtnHover,
        press: ResourceTypes.buyFeaturePlusBtnPressed,
        disable: ResourceTypes.buyFeaturePlusBtnDisable,
      },
      () => {
        if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
          // eslint-disable-next-line no-plusplus
          this.betAmount++;
          setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
          this.updateBets();
          this.handleDisable();
          setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
          AudioApi.play({ type: ISongs.SONG_SFX_UI_BetChange });
        }
      },
    );
    button.position.set(FEATURE_POPUP_PLUS_BTN_POSITION_X, FEATURE_POPUP_PLUS_BTN_POSITION_Y);
    button.scale.set(0.5, 0.5);
    button.anchor.set(0.5, 0.5);

    return button;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureInput));
    input.y = FEATURE_POPUP_INPUT_POSITION_Y;
    input.x = FEATURE_POPUP_INPUT_POSITION_X;
    input.anchor.set(0.5, 0);

    return input;
  }

  private initBetValue(): AutoResizeText {
    const betValue = new AutoResizeText(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      { ...betValueStyle, maxWidth: FEATURE_POPUP_INPUT_WIDTH - 10 },
    );
    betValue.y = FEATURE_POPUP_BET_VALUE_POSITION_Y + 28;
    betValue.x = FEATURE_POPUP_BET_VALUE_POSITION_X;
    betValue.anchor.set(0.5, 0.5);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const button = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureCancelBtn,
        hover: ResourceTypes.buyFeatureCancelBtnHover,
        press: ResourceTypes.buyFeatureCancelBtnPressed,
        disable: ResourceTypes.buyFeatureCancelBtnDisable,
      },
      () => {
        AudioApi.play({ type: ISongs.SONG_SFX_UI_Close });
        this.visible = false;
        setIsBuyFeaturePopupOpened(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
        eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
        this.handleDisable();
      },
    );

    button.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
    button.x = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
    button.anchor.set(0.5);

    return button;
  }

  private initOkBtn(): SpriteButton {
    const button = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureOkBtn,
        hover: ResourceTypes.buyFeatureOkBtnHover,
        press: ResourceTypes.buyFeatureOkBtnPressed,
        disable: ResourceTypes.buyFeatureOkBtnDisable,
      },
      () => {
        AudioApi.play({ type: ISongs.SONG_SFX_UI_General });
        eventManager.emit(
          EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
          this.getTotalCost(),
          this.betSettings.bets[this.betAmount]!,
        );
        this.visible = false;
      },
    );

    button.y = FEATURE_POPUP_OK_BTN_POSITION_Y;
    button.x = FEATURE_POPUP_OK_BTN_POSITION_X;
    button.anchor.set(0.5);

    return button;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.linesAmount;
      }) + 1
    );
  };

  private updateBets(): void {
    this.totalCostTextAmount.text = this.getTotalCost();
    this.betValue.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
  }

  private getTotalCost = (): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetValue = (): number => {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (): number => {
    const bonuses = setBonuses();
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.type === FeatureTypes.SPECIAL_ROUND && bonus.id === bonusIds[GameMode.BUY_FEATURE])
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];

    this.minusButton.disable = bet === this.betSettings!.minBet;

    this.plusButton.disable = bet === this.betSettings!.maxBet;

    this.okBtn.disable = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount());
  };

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (_width: number, _height: number, _x: number, _y: number, _scale: number): void => {
    this.handleResize(this.window.width, this.window.height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = SAFE_AREA_PORTRAIT_HEIGHT / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y;
    } else {
      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.y = SAFE_AREA_LANDSCAPE_HEIGHT / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y;
    }
  }
}

export default BuyFeaturePopup;
